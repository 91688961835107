<template>
	<div>
		<b-modal
			id="modal-add-bonus"
			cancel-variant="outline-secondary"
			ok-title="Add"
			cancel-title="Close"
			ok-variant="primary"
			centered
			title="New Bonus"
			size="lg"
			no-enforce-focus
			@ok="confirmAddBonus"
			@hidden="resetForm"
		>
			<!-- form -->
			<validation-observer
				#default="{ handleSubmit }"
				ref="refFormObserver"
			>
				<b-form
					@submit.prevent="handleSubmit(validationFormBonus)"
					@reset.prevent="resetForm"
				>

					<!-- Copy Bonus -->
					<b-row>
						<b-col
							cols="12"
							class="mb-2 border-bottom"
						>
							<b-form-group
								label-for="copyBonus"
								class="d-inline-block w-50 mr-2 mb-0 pt-2 pb-3"
							>
								<label class="">Copy Bonus</label>
								<v-select
									id="copyBonus"
									:options="bonusCodeOptions"
									:reduce="val => val"
									label="name"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									placeholder="Select Bonus"
									@input="selectBonusCode"
								/>
							</b-form-group>
							<b-button
								variant="primary"
								@click="copyBonusCode"
							>
								Copy
							</b-button>
						</b-col>
					</b-row>

					<b-row>
						<!-- Name -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<!-- Field: Name -->
							<b-form-group
								label-for="name"
							>
								<label class="mb-1"><span class="text-danger">*</span> Name</label>
								<validation-provider
									#default="{ errors }"
									name="bonusName"
									rules="required"
								>
									<b-form-input
										id="name"
										v-model="bonusData.name"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										placeholder="Enter Bonus Name"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Group -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="group"
							>
								<label class="mb-1"><span class="text-danger">*</span> Group</label>
								<validation-provider
									#default="{ errors }"
									name="group"
									rules="required"
								>
									<v-select
										v-model="bonusData.bonus_group_id"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										:options="groupNameOptions"
										:reduce="val => val.id"
										:clearable="false"
										placeholder="Select Bonus Group"
										label="name"
										input-id="group"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Code -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="code"
							>
								<label class="mb-1">Code</label>
								<b-form-input
									id="code"
									v-model="bonusData.code"
									placeholder="Enter bonus code"
								/>
							</b-form-group>
						</b-col>

						<!-- Value -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="value"
							>
								<label class="mb-1">Value</label>
								<validation-provider
									#default="{ errors }"
									name="value"
									rules="numeric|min_value:1"
								>
									<b-form-input
										id="value"
										v-model="bonusData.value"
										placeholder="Enter bonus value, Must be at least 1"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Rollover -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="rollover"
							>
								<label class="mb-1">Rollover</label>
								<validation-provider
									#default="{ errors }"
									name="rollover"
									rules="numeric|numeric|min_value:1|max_value:50"
								>
									<b-form-input
										id="rollover"
										v-model="bonusData.rollover"
										placeholder="Enter bonus rollover, Must be from 1 - 50."
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Currency -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="currency"
							>
								<label class="mb-1">Currency</label>
								<validation-provider
									#default="{ errors }"
									name="currency"
									rules=""
								>
									<v-select
										v-model="bonusData.currency"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										:options="currencyOptions"
										:reduce="val => val.value"
										:clearable="true"
										placeholder="Select Currency"
										label="value"
										input-id="currency"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Max Bonus Amount -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="maxAmount"
							>
								<label class="mb-1">Max Bonus Amount</label>
								<validation-provider
									#default="{ errors }"
									name="maxAmount"
									rules="numeric"
								>
									<b-form-input
										id="maxAmount"
										v-model="bonusData.max_bonus_amount"
										placeholder="Enter max bonus amount"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Min Transfer Amount -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="minTransferAmount"
							>
								<label class="mb-1">Min Transfer Amount</label>
								<validation-provider
									#default="{ errors }"
									name="minTransferAmount"
									rules="numeric"
								>
									<b-form-input
										id="minTransferAmount"
										v-model="bonusData.min_transfer"
										placeholder="Enter Min Transfer Amount"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Product -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="product"
							>
								<label class="mb-1">Product</label>
								<validation-provider
									#default="{ errors }"
									name="product"
								>
									<v-select
										v-model="bonusData.product_id"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										:options="productOptions"
										:reduce="val => val.id"
										placeholder="Select Product"
										label="name"
										input-id="product"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Type -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="type"
							>
								<label class="mb-1"><span class="text-danger">*</span> Type</label>
								<validation-provider
									#default="{ errors }"
									name="type"
									rules="required"
								>
									<v-select
										v-model="bonusData.type"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										:options="typeOptions"
										:reduce="val => val.value"
										:clearable="false"
										placeholder="Select Type"
										input-id="type"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Bonus Type -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="type"
							>
								<label class="mb-1"><span class="text-danger">*</span> Bonus Type</label>
								<validation-provider
									#default="{ errors }"
									name="bonusType"
									rules="required"
								>
									<v-select
										v-model="bonusData.bonus_type"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										:options="bonusTypeOptions"
										:reduce="val => val.id"
										label="name"
										:clearable="false"
										placeholder="Select Bonus Type"
										input-id="type"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Start Effective -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="startEffective"
							>
								<label class="mb-1">Start Effective</label>
								<flat-pickr
									id="startEffective"
									v-model="bonusData.start_effective"
									class="form-control"
									placeholder="YYYY-MM-DD 00:00:00"
									:config="{
										enableTime: true,
										allowInput: true,
										dateFormat: 'Y-m-d H:i:ss',
										time_24hr: true,
									}"
								/>
							</b-form-group>
						</b-col>

						<!-- End Effective -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="startEffective"
							>
								<label class="mb-1">End Effective</label>
								<flat-pickr
									id="startEffective"
									v-model="bonusData.end_effective"
									class="form-control"
									placeholder="YYYY-MM-DD 00:00:00"
									:config="{
										enableTime: true,
										allowInput: true,
										dateFormat: 'Y-m-d H:i:ss',
										time_24hr: true,
									}"
								/>
							</b-form-group>
						</b-col>

						<!-- Start SignUp -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="startSignUp"
							>
								<label class="mb-1">Start Signup</label>
								<flat-pickr
									id="startSignup"
									v-model="bonusData.start_signup"
									class="form-control"
									placeholder="YYYY-MM-DD 00:00:00"
									:config="{
										enableTime: true,
										allowInput: true,
										dateFormat: 'Y-m-d H:i:ss',
										time_24hr: true,
									}"
								/>
							</b-form-group>
						</b-col>

						<!-- End SignUp -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="startSignUp"
							>
								<label class="mb-1">End Signup</label>
								<flat-pickr
									id="startSignup"
									v-model="bonusData.end_signup"
									class="form-control"
									placeholder="YYYY-MM-DD 00:00:00"
									:config="{
										enableTime: true,
										allowInput: true,
										dateFormat: 'Y-m-d H:i:ss',
										time_24hr: true,
									}"
								/>
							</b-form-group>
						</b-col>
						
						<b-col
							cols="12"
						>
							<b-form-group
								label-for="content"
							>
								<label class="mb-1"><span class="text-danger">*</span> {{ $t('Content') }}</label>
								<validation-provider
									#default="{ errors }"
									name="content"
									rules="required"
								>
									<Editor
                                        :api-key = API_KEY
                      					:init="init"
										v-model="bonusData.content"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										class="h-100"
                  					/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col
							cols="12"
						>
							<b-form-group
								label-for="banner"
							>
								<label for=""><span class="text-danger">*</span> {{ $t('Banner') }} <span class="text-secondary">(Không > 10Mb)</span></label>
								<validation-provider
									#default="{ errors }"
									name="banner"
									rules="size:10240"
								>
									<b-form-file
										v-model="bonusData.banner"
										input-id="banner"
										accept="image/jpeg,image/png,image/gif,image/svg,image/jpg"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
							<b-button
								v-show="bonusData.banner"
								variant="outline-danger"
								class="btn-sm btn-icon"
								@click="bonusData.banner = null"
							>
								<feather-icon icon="XIcon"/>
							</b-button>
						</b-col>						
					</b-row>
				</b-form>
			</validation-observer>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import {
	BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BFormFile, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend, BFormTextarea, BBadge, BFormInvalidFeedback,
} from 'bootstrap-vue'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import baseUrl from '@/libs/base-url'
import Editor from '@tinymce/tinymce-vue'
import API_KEY from "@/auth/apikey/apikeyeditor";
export default {
	components: {
		BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BFormFile, BButton, BInputGroup, BInputGroupPrepend, BModal, BFormTextarea, BBadge,
		BInputGroupAppend,
		BFormInvalidFeedback,
		ValidationProvider,
		ValidationObserver,
		vSelect,
		flatPickr,
    	Editor
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
		bonusCodeOptions: {
			type: Array,
			default: null,
		},
		groupNameOptions: {
			type: Array,
			default: null,
		},
		currencyOptions: {
			type: Array,
			default: null,
		},
		productOptions: {
			type: Array,
			default: null,
		},
		typeOptions: {
			type: Array,
			default: null,
		},
		bonusTypeOptions: {
			type: Array,
			default: null,
		},
		content: {
			type: Object,
			required: false,
			default: null,
		},
		bonusGroupId: {
			type: Number,
			required: false,
			default: null,
		},
	},
	data() {
		return {
            API_KEY,
			required,
			copyBonusCodeData: null,
            init:{
                height: 600,
                content_style: this.$store.state.appConfig.layout.skin === "dark" ? "body { color: white; background-color:#293046 }" : "",
                plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'wordcount'
                ],
                toolbar: ' undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent inden |image',
                skin: this.$store.state.appConfig.layout.skin === "dark" ? 'oxide-dark' : '',
                image_title: true,
                automatic_uploads: true,
                file_picker_types: 'image',
                file_picker_callback: function (cb, value, meta) {
                    var input = document.createElement('input');
                    input.setAttribute('type', 'file');
                    input.setAttribute('accept', 'image/*');
                    input.onchange = function () {
                        var file = this.files[0];

                        var reader = new FileReader();
                        reader.onload = function () {
                            var id = 'blobid' + (new Date()).getTime();
                            var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
                            var base64 = reader.result.split(',')[1];
                            var blobInfo = blobCache.create(id, file, base64);
                            blobCache.add(blobInfo);
                            cb(blobInfo.blobUri(), { title: file.name });
                        };
                        reader.readAsDataURL(file);
                    };

                    input.click();

                },

            }

    }
	},
	watch: {
		bonusData: {
			handler(newVal) {
				this.bonusData.start_effective = newVal.start_effective
				this.bonusData.end_effective = newVal.end_effective

				this.bonusData.start_signup = newVal.start_signup
				this.bonusData.end_signup = newVal.end_signup
				

				if (newVal.product_id == null) {
					this.bonusData.product_id = ''
				}

				if (newVal.currency == null) {
					this.bonusData.currency = ''
				}
			},
			deep: true,
		},
	},
	methods: {
		validationFormBonus() {
			this.$refs.refFormObserver.validate().then(success => {
				if (success) {
					store.dispatch('bonus/addBonusCode', this.bonusData)
						.then(response => {
							if (response.status === 200) {
								if (response.data.code == '00') {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message || 'success',
											icon: 'CheckIcon',
											variant: 'success',
										},
									})
									this.$nextTick(() => {
										this.$bvModal.hide('modal-add-bonus')
										this.resetForm()
									})
									this.$emit('refetch-data')
								} else {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message,
											icon: 'AlertTriangleIcon',
											variant: 'danger',
										},
									})
								}
							}
						})
						.catch(error => {
							const { response } = error
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response,
									icon: 'AlertTriangleIcon',
									variant: 'danger',
								},
							})
						})
				}
			})
		},
		confirmAddBonus(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.validationFormBonus()
		},
		selectBonusCode(bonusCode) {
			this.copyBonusCodeData = bonusCode
		},
		copyBonusCode() {
			if (this.copyBonusCodeData) {
				this.bonusData = {
					name: '',
					bonus_group_id: this.copyBonusCodeData.group_id,
					code: '',
					value: this.copyBonusCodeData.value / 1000,
					rollover: this.copyBonusCodeData.rollover,
					currency: this.copyBonusCodeData.currency,
					max_bonus_amount: this.copyBonusCodeData.max_bonus_amount / 1000,
					min_transfer: this.copyBonusCodeData.min_transfer_amount / 1000,
					product_id: this.copyBonusCodeData.product_id || '',
					start_effective: this.copyBonusCodeData.start_effective || '',
					end_effective: this.copyBonusCodeData.end_effective || '',
					start_signup: this.copyBonusCodeData.start_signup || '',
					end_signup: this.copyBonusCodeData.end_signup || '',
					type: this.copyBonusCodeData.discount_type,
					content: this.copyBonusCodeData.content,
					bonus_type: this.copyBonusCodeData.bonus_type_id,
				}
			}
		},
		resetForm() {
			this.bonusData = {
				name: '',
				bonus_group_id: '',
				code: '',
				value: '',
				rollover: '',
				currency: 'VND',
				max_bonus_amount: '',
				min_transfer: '',
				product_id: '',
				start_effective: '',
				end_effective: '',
				start_signup: '',
				end_signup: '',
				type: '',
				content: '',
				banner: null,
				bonus_type: '',
			}
		}
	},
	setup() {
		const toast = useToast()
		const blankBonusData = {
			name: '',
			bonus_group_id: '',
			code: '',
			value: '',
			rollover: '',
			currency: 'VND',
			max_bonus_amount: '',
			min_transfer: '',
			product_id: '',
			start_effective: '',
			end_effective: '',
			start_signup: '',
			end_signup: '',
			type: '',
			start_effective: '',
			end_effective: '',
			start_signup: '',
			end_signup: '',
			content: '',
			banner: null,
			bonus_type: '',
		}
		const bonusData = ref(JSON.parse(JSON.stringify(blankBonusData)))

		return {
			bonusData,
		}
	},
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
