var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-add-bonus","cancel-variant":"outline-secondary","ok-title":"Add","cancel-title":"Close","ok-variant":"primary","centered":"","title":"New Bonus","size":"lg","no-enforce-focus":""},on:{"ok":_vm.confirmAddBonus,"hidden":_vm.resetForm}},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validationFormBonus)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{staticClass:"mb-2 border-bottom",attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"d-inline-block w-50 mr-2 mb-0 pt-2 pb-3",attrs:{"label-for":"copyBonus"}},[_c('label',{},[_vm._v("Copy Bonus")]),_c('v-select',{attrs:{"id":"copyBonus","options":_vm.bonusCodeOptions,"reduce":function (val) { return val; },"label":"name","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"placeholder":"Select Bonus"},on:{"input":_vm.selectBonusCode}})],1),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.copyBonusCode}},[_vm._v(" Copy ")])],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"name"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" Name")]),_c('validation-provider',{attrs:{"name":"bonusName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"placeholder":"Enter Bonus Name"},model:{value:(_vm.bonusData.name),callback:function ($$v) {_vm.$set(_vm.bonusData, "name", $$v)},expression:"bonusData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"group"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" Group")]),_c('validation-provider',{attrs:{"name":"group","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.groupNameOptions,"reduce":function (val) { return val.id; },"clearable":false,"placeholder":"Select Bonus Group","label":"name","input-id":"group"},model:{value:(_vm.bonusData.bonus_group_id),callback:function ($$v) {_vm.$set(_vm.bonusData, "bonus_group_id", $$v)},expression:"bonusData.bonus_group_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{staticClass:"mb-1"},[_vm._v("Code")]),_c('b-form-input',{attrs:{"id":"code","placeholder":"Enter bonus code"},model:{value:(_vm.bonusData.code),callback:function ($$v) {_vm.$set(_vm.bonusData, "code", $$v)},expression:"bonusData.code"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"value"}},[_c('label',{staticClass:"mb-1"},[_vm._v("Value")]),_c('validation-provider',{attrs:{"name":"value","rules":"numeric|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"value","placeholder":"Enter bonus value, Must be at least 1"},model:{value:(_vm.bonusData.value),callback:function ($$v) {_vm.$set(_vm.bonusData, "value", $$v)},expression:"bonusData.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"rollover"}},[_c('label',{staticClass:"mb-1"},[_vm._v("Rollover")]),_c('validation-provider',{attrs:{"name":"rollover","rules":"numeric|numeric|min_value:1|max_value:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"rollover","placeholder":"Enter bonus rollover, Must be from 1 - 50."},model:{value:(_vm.bonusData.rollover),callback:function ($$v) {_vm.$set(_vm.bonusData, "rollover", $$v)},expression:"bonusData.rollover"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"currency"}},[_c('label',{staticClass:"mb-1"},[_vm._v("Currency")]),_c('validation-provider',{attrs:{"name":"currency","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.currencyOptions,"reduce":function (val) { return val.value; },"clearable":true,"placeholder":"Select Currency","label":"value","input-id":"currency"},model:{value:(_vm.bonusData.currency),callback:function ($$v) {_vm.$set(_vm.bonusData, "currency", $$v)},expression:"bonusData.currency"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"maxAmount"}},[_c('label',{staticClass:"mb-1"},[_vm._v("Max Bonus Amount")]),_c('validation-provider',{attrs:{"name":"maxAmount","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"maxAmount","placeholder":"Enter max bonus amount"},model:{value:(_vm.bonusData.max_bonus_amount),callback:function ($$v) {_vm.$set(_vm.bonusData, "max_bonus_amount", $$v)},expression:"bonusData.max_bonus_amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"minTransferAmount"}},[_c('label',{staticClass:"mb-1"},[_vm._v("Min Transfer Amount")]),_c('validation-provider',{attrs:{"name":"minTransferAmount","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"minTransferAmount","placeholder":"Enter Min Transfer Amount"},model:{value:(_vm.bonusData.min_transfer),callback:function ($$v) {_vm.$set(_vm.bonusData, "min_transfer", $$v)},expression:"bonusData.min_transfer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"product"}},[_c('label',{staticClass:"mb-1"},[_vm._v("Product")]),_c('validation-provider',{attrs:{"name":"product"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.productOptions,"reduce":function (val) { return val.id; },"placeholder":"Select Product","label":"name","input-id":"product"},model:{value:(_vm.bonusData.product_id),callback:function ($$v) {_vm.$set(_vm.bonusData, "product_id", $$v)},expression:"bonusData.product_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"type"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" Type")]),_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.typeOptions,"reduce":function (val) { return val.value; },"clearable":false,"placeholder":"Select Type","input-id":"type"},model:{value:(_vm.bonusData.type),callback:function ($$v) {_vm.$set(_vm.bonusData, "type", $$v)},expression:"bonusData.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"type"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" Bonus Type")]),_c('validation-provider',{attrs:{"name":"bonusType","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.bonusTypeOptions,"reduce":function (val) { return val.id; },"label":"name","clearable":false,"placeholder":"Select Bonus Type","input-id":"type"},model:{value:(_vm.bonusData.bonus_type),callback:function ($$v) {_vm.$set(_vm.bonusData, "bonus_type", $$v)},expression:"bonusData.bonus_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"startEffective"}},[_c('label',{staticClass:"mb-1"},[_vm._v("Start Effective")]),_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"startEffective","placeholder":"YYYY-MM-DD 00:00:00","config":{
										enableTime: true,
										allowInput: true,
										dateFormat: 'Y-m-d H:i:ss',
										time_24hr: true,
									}},model:{value:(_vm.bonusData.start_effective),callback:function ($$v) {_vm.$set(_vm.bonusData, "start_effective", $$v)},expression:"bonusData.start_effective"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"startEffective"}},[_c('label',{staticClass:"mb-1"},[_vm._v("End Effective")]),_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"startEffective","placeholder":"YYYY-MM-DD 00:00:00","config":{
										enableTime: true,
										allowInput: true,
										dateFormat: 'Y-m-d H:i:ss',
										time_24hr: true,
									}},model:{value:(_vm.bonusData.end_effective),callback:function ($$v) {_vm.$set(_vm.bonusData, "end_effective", $$v)},expression:"bonusData.end_effective"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"startSignUp"}},[_c('label',{staticClass:"mb-1"},[_vm._v("Start Signup")]),_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"startSignup","placeholder":"YYYY-MM-DD 00:00:00","config":{
										enableTime: true,
										allowInput: true,
										dateFormat: 'Y-m-d H:i:ss',
										time_24hr: true,
									}},model:{value:(_vm.bonusData.start_signup),callback:function ($$v) {_vm.$set(_vm.bonusData, "start_signup", $$v)},expression:"bonusData.start_signup"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"startSignUp"}},[_c('label',{staticClass:"mb-1"},[_vm._v("End Signup")]),_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"startSignup","placeholder":"YYYY-MM-DD 00:00:00","config":{
										enableTime: true,
										allowInput: true,
										dateFormat: 'Y-m-d H:i:ss',
										time_24hr: true,
									}},model:{value:(_vm.bonusData.end_signup),callback:function ($$v) {_vm.$set(_vm.bonusData, "end_signup", $$v)},expression:"bonusData.end_signup"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"content"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Content')))]),_c('validation-provider',{attrs:{"name":"content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var errors = ref.errors;
return [_c('Editor',{staticClass:"h-100",attrs:{"api-key":_vm.API_KEY,"init":_vm.init,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.bonusData.content),callback:function ($$v) {_vm.$set(_vm.bonusData, "content", $$v)},expression:"bonusData.content"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"banner"}},[_c('label',{attrs:{"for":""}},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Banner'))+" "),_c('span',{staticClass:"text-secondary"},[_vm._v("(Không > 10Mb)")])]),_c('validation-provider',{attrs:{"name":"banner","rules":"size:10240"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var errors = ref.errors;
return [_c('b-form-file',{attrs:{"input-id":"banner","accept":"image/jpeg,image/png,image/gif,image/svg,image/jpg"},model:{value:(_vm.bonusData.banner),callback:function ($$v) {_vm.$set(_vm.bonusData, "banner", $$v)},expression:"bonusData.banner"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.bonusData.banner),expression:"bonusData.banner"}],staticClass:"btn-sm btn-icon",attrs:{"variant":"outline-danger"},on:{"click":function($event){_vm.bonusData.banner = null}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }